import * as StoresDataOperations from './operations';
import * as StoresDataSelectors from './selectors';

import reducer from './reducers';

export {
  StoresDataOperations,
  StoresDataSelectors,
};

export default reducer;
