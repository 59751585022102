/**
 * Return the order controls section of the state.
 *
 * @param state
 */
export function returnMySliceOfState(state) {
  return state.OrdersData;
}

/**
 * Check if we are fetching any items in the order.
 *
 * @param state
 * @returns boolean
 */
export function isFetching(state) {
  const curState = returnMySliceOfState(state);

  return (curState.order_isFetching);
}

/**
 * Check if we are loading any items in the order.
 *
 * @param state
 * @returns boolean
 */
export function isLoading(state) {
  // const curState = returnMySliceOfState(state);

  // return ( curState.order_isFetching);
  return false;
}

/**
 * Check if we are ready to display.
 *
 * @param state
 * @returns boolean
 */
export function isReady(state) {
  return (!isFetching(state) && !isLoading(state));
}

/**
 * Return an item from our state object.
 *
 * @param state
 * @param item
 * @returns {*}
 */
export function returnItemFromState(state, item) {
  const curState = returnMySliceOfState(state);
  return curState[item];
}
