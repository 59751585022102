import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import './PrivacyPolicyModal.scss';

const useStateWithLocalStorage = (localStorageKey, defaultValue) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || defaultValue,
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [localStorageKey, value]);

  return [value, setValue];
};

function PrivacyPolicyModal(props) {
  const { isOpen } = props;

  const [shownModal, setModal] = useStateWithLocalStorage('shownPrivacyPolicyNotice', isOpen);

  const toggle = () => setModal(!shownModal);

  return (
    <Modal isOpen={!shownModal} toggle={toggle} className="PrivacyPolicyModal">
      <ModalHeader toggle={toggle}>Our Privacy Policy has been Updated</ModalHeader>
      <ModalBody>
        We have recently updated our privacy policy, to understand more about how this affects you please <a href="https://www.nfiptn.com/privacy-policy.php" target="_blank" rel="noopener noreferrer">view it here</a>.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

PrivacyPolicyModal.propTypes = {
  isOpen: PropTypes.bool,
};

PrivacyPolicyModal.defaultProps = {
  isOpen: true,
};

export default PrivacyPolicyModal;
