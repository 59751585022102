import React from 'react';

import './CopyrightNotice.scss';
import { Container } from 'reactstrap';

function CopyrightNotice() {
  return (
    <div className="CopyrightNotice">
      <Container>
        <div className="float-right">
          <a href="https://www.nfiptn.com/privacy-policy.php" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
        &copy; 2020 Nutricion Fundamental, Inc. All rights reserved.
      </Container>
    </div>
  );
}

CopyrightNotice.propTypes = {};

CopyrightNotice.defaultProps = {};

export default CopyrightNotice;
